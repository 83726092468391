function breakText(txt, el) {
    // The word after which you want to insert a line break
    const wordToBreakAfter = txt;
        
    // Get the text node
    // const paragraph = document.getElementById('text');
    const paragraph = el;
    
    // Get the text content of the paragraph
    const text = paragraph.textContent;
    
    // Find the position of the word
    const position = text.indexOf(wordToBreakAfter);
    
    if (position !== -1) {
        // Create the parts: before the word, the word, and after the word
        const beforeWord = text.substring(0, position + wordToBreakAfter.length);
        const afterWord = text.substring(position + wordToBreakAfter.length);
        
        // Clear the existing content of the paragraph
        paragraph.textContent = '';
        
        // Create a text node for the text before the word
        const beforeTextNode = document.createTextNode(beforeWord);
        
        // Create a text node for the text after the word
        const afterTextNode = document.createTextNode(afterWord);
        
        // Create a <br> element to serve as a line break
        const lineBreak = document.createElement('br');
        
        // Append the parts to the paragraph
        paragraph.appendChild(beforeTextNode);
        paragraph.appendChild(lineBreak);
        paragraph.appendChild(afterTextNode);
    }
}




document.addEventListener("DOMContentLoaded", function () {
    const aiTitleText = document.querySelector('.wdb-series-landing--ai .wdb-series-landing-title h1');
    if (aiTitleText) {
        breakText('AI', aiTitleText);
    }    
});